import {
  createSettingsParams,
  SettingsParamType,
} from '@wix/yoshi-flow-editor/tpa-settings';

import { PostImageFill, PostImageRatio } from '../../types';

export type ISettingsParams = {
  aboutSectionTitle: SettingsParamType.Text;
  aboutDescriptionPlaceholder: SettingsParamType.Text;
  badgesSectionTitle: SettingsParamType.Text;
  overviewSectionTitle: SettingsParamType.Text;
  blogPostsTitle: SettingsParamType.Text;
  blogPostsEmptyStateTitle: SettingsParamType.Text;
  blogPostsEmptyStateDescription: SettingsParamType.Text;
  showAboutSection: SettingsParamType.Boolean;
  showBadgesSection: SettingsParamType.Boolean;
  showOverviewSection: SettingsParamType.Boolean;
  showBlogPostsSection: SettingsParamType.Boolean;
  showJoinDate: SettingsParamType.Boolean;
  showCommentsStats: SettingsParamType.Boolean;
  showLikesReceivedStats: SettingsParamType.Boolean;
  showTopCommentsStats: SettingsParamType.Boolean;
  postListPageSize: SettingsParamType.Number;
  postImageFill: SettingsParamType.String;
  postImageRatio: SettingsParamType.Number;
  showPostCover: SettingsParamType.Boolean;
  showPostPublishDate: SettingsParamType.Boolean;
  showPostReadingTime: SettingsParamType.Boolean;
  showPostExcerpt: SettingsParamType.Boolean;
  showPostViewsCounter: SettingsParamType.Boolean;
  showPostCommentsCounter: SettingsParamType.Boolean;
  showPostLikesCounter: SettingsParamType.Boolean;
  emptyStateTitle: SettingsParamType.Text;
  emptyStateText: SettingsParamType.Text;
  isMobileSectionSettingsEnabled: SettingsParamType.Boolean;
};

export default createSettingsParams<ISettingsParams>({
  aboutSectionTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('about-page.about'),
  },
  aboutDescriptionPlaceholder: {
    key: 'placeholder',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => {
      return t('settings.texts-tab.placeholder-default-text');
    },
  },
  badgesSectionTitle: {
    key: 'badgesTitle',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('about-page.badges'),
  },
  overviewSectionTitle: {
    key: 'overviewTitle',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('about.widget.overview'),
  },
  blogPostsTitle: {
    key: 'postListTitle',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('recent-posts.posts'),
  },
  blogPostsEmptyStateTitle: {
    key: 'postListEmptyStateTitle',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('no-posts.title'),
  },
  blogPostsEmptyStateDescription: {
    key: 'postListEmptyStateLinkText',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('no-posts.write-your'),
  },
  showAboutSection: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showBadgesSection: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showOverviewSection: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showBlogPostsSection: {
    key: 'showPostListSection',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showJoinDate: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showCommentsStats: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showLikesReceivedStats: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showTopCommentsStats: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  postListPageSize: {
    type: SettingsParamType.Number,
    getDefaultValue: () => 3,
  },
  postImageFill: {
    key: 'postListLayoutResizing',
    type: SettingsParamType.String,
    getDefaultValue: () => PostImageFill.Fill,
  },
  postImageRatio: {
    key: 'postListImageRatio',
    type: SettingsParamType.Number,
    getDefaultValue: () => PostImageRatio.OneToOne,
  },
  showPostCover: {
    key: 'postListShowCoverImage',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showPostPublishDate: {
    key: 'postListShowPublishDate',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showPostReadingTime: {
    key: 'postListShowReadingTime',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showPostExcerpt: {
    key: 'postListShowDescription',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showPostViewsCounter: {
    key: 'postListShowViewsCounter',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showPostCommentsCounter: {
    key: 'postListShowCommentsCounter',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showPostLikesCounter: {
    key: 'postListShowLikesCounter',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  emptyStateTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('no-about.title'),
  },
  emptyStateText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('no-about.content'),
  },
  isMobileSectionSettingsEnabled: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
});
