import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { createStore } from '../../store';
import { getControllerProps, getInitialState } from './controller-utils';
import {
  createSettingsListener,
  registerControllerListeners,
  registerUserListeners,
} from './controller-listeners';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig: { wixCodeApi, setProps },
}) => {
  const settingsListener = createSettingsListener();

  return {
    async pageReady() {
      const store = createStore(
        { flowAPI, wixCodeApi },
        await getInitialState(flowAPI),
      );

      store.subscribe(() => setProps(store.getState()));
      setProps(getControllerProps(flowAPI, store));
      registerUserListeners(flowAPI, store);
      registerControllerListeners(flowAPI, settingsListener, store);
    },
    updateConfig(_, { publicData }) {
      settingsListener.notify(publicData.COMPONENT || {});
    },
  };
};

export default createController;
