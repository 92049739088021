import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';

import { profileCardAppDefId } from '../../constants';
import {
  FlowApi,
  ProfileCardAPI,
  ProfileChangeEvent,
  SettingsEvent,
  SettingsSection,
  SettingsSectionState,
  SettingsTab,
} from '../../types';
import { Store } from '../../store';
import { initialStateRefetch } from '../../store/thunk';
import { badgesThunk } from '../../store/slices';
import {
  openTab,
  openSection,
  changeSectionState,
} from '../../store/slices/app-settings/slice';
import { blockMember } from '../../store/slices/member/slice';
import { setIsPublicProfilePreview } from '../../store/slices/ui/slice';

export const createSettingsListener = (publicData: Object = {}) => {
  return createEventHandler<SettingsEvent>(publicData);
};

type SettingsEventHandler = ReturnType<typeof createSettingsListener>;

const registerSettingsListeners = (
  settingsEventHandler: SettingsEventHandler,
  store: Store,
) => {
  settingsEventHandler.on('tabChanged', (settingsTab: SettingsTab) => {
    store.dispatch(openTab(settingsTab));
  });

  settingsEventHandler.on(
    'sectionChanged',
    (settingsSection: SettingsSection) => {
      store.dispatch(openSection(settingsSection));
    },
  );

  settingsEventHandler.on(
    'sectionStateChanged',
    (settingsSectionState: SettingsSectionState) => {
      store.dispatch(changeSectionState(settingsSectionState));
    },
  );
};

const registerProfileCardListeners = async (
  { controllerConfig }: FlowApi,
  store: Store,
) => {
  const { site } = controllerConfig.wixCodeApi;
  const profileCardAPI = (await site.getPublicAPI(
    profileCardAppDefId,
  )) as null | ProfileCardAPI;

  profileCardAPI?.registerToProfileChange?.((payload) => {
    switch (payload.event) {
      case ProfileChangeEvent.BadgeAssigned:
        store.dispatch(
          badgesThunk.fetchAssignedBadges(payload.assignedBadgeIds),
        );
        return;
      case ProfileChangeEvent.MemberBlocked:
        store.dispatch(blockMember());
        return;
      case ProfileChangeEvent.PublicProfilePreviewChanged:
        store.dispatch(
          setIsPublicProfilePreview(payload.isPublicProfilePreview),
        );
        return;
    }
  });
};

export const registerControllerListeners = async (
  flowAPI: FlowApi,
  settingsEventHandler: SettingsEventHandler,
  store: Store,
) => {
  const { isEditor, isSSR, isViewer } = flowAPI.environment;

  if (isEditor) {
    registerSettingsListeners(settingsEventHandler, store);
  }

  if (isViewer && !isSSR) {
    return registerProfileCardListeners(flowAPI, store);
  }
};

export const registerUserListeners = (flowAPI: FlowApi, store: Store) => {
  const { appParams, wixCodeApi, setProps } = flowAPI.controllerConfig;

  wixCodeApi.site.onInstanceChanged(
    ({ instance }) => setProps({ instance }),
    appParams.appDefinitionId,
  );

  wixCodeApi.user.onLogin(async () => {
    store.dispatch(initialStateRefetch());
  });
};
