import { createAsyncThunk } from '@reduxjs/toolkit';

import { getInitialState } from '../components/Profile/controller-utils';
import { ThunkApiConfig } from '../types';
import { RootState } from './root-reducer';

export const initialStateRefetch = createAsyncThunk<
  RootState,
  void,
  ThunkApiConfig
>('root/initialStateRefetch', async (_, { extra: { flowAPI } }) => {
  const initialState = await getInitialState(flowAPI);
  return initialState;
});
